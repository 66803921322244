import React from 'react';
import Logo from './components/Logo';
import "./styles/main.css"

const App: React.FC = () => {
  return (
    <div className="App">
      <Logo />
    </div>
  );
}

export default App;
