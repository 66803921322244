import React from 'react';
import logo from '../assets/logo.png';

function Logo() {
  return (
    <div className='main'>
      <h1>Neural Chowder Inc</h1>
      <img src={logo} alt="Company Logo" />
      <p className='description'>Working for AI</p>
    </div>
  );
}

export default Logo;